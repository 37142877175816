import React from 'react'
import { Container, Row, Col, Carousel, CarouselIndicators, CarouselItem } from 'reactstrap';
import { Link } from 'gatsby';

import testiBg from '../images/testi-bg.jpg'
import userImage from '../images/user-image.jpg'
import testi1 from '../images/testi-1.jpg'
import testi2 from '../images/testi-2.jpg'
import testi3 from '../images/testi-3.jpg'

export type testiType = {
    id: number,
    image: any,
    pic: any,
    name: string,
    from: string,
    text: string
}
export type TestimonialsProps = {
    testis?: testiType[],
    colButton?: boolean
}
const testisgen: testiType[] = [
    {
        id: 1,
        image: testi1,
        pic: userImage,
        name: "Peter Jackson",
        from: "GOLD COAST, QLD",
        text: "We feel more secure as we can see that our superannuation is growing. We manage well with  our budget, however if I want to take that overseas trip or last minute cruise, we know the money is still easy to access, which is nice to know."
    },
    {
        id: 1,
        image: testi2,
        pic: userImage,
        name: "Rob and Kim Virgona",
        from: "GOLD COAST, QLD",
        text: "Solutions takes the worry out of doing it yourself. Working with them takes the confusion away from it."
    },
    {
        id: 1,
        image: testi3,
        pic: userImage,
        name: "Andrew McDougall",
        from: "GOLD COAST, QLD",
        text: "I had just turned 50, and with my debt under control, l wanted to focus on building wealth for retirement. Solutions stood out because they were very helpful, in advising me on the best path to take in these uncertain times."
    }
]
export function Testimonials(props: TestimonialsProps) {
    const testis = props.testis ? props.testis : testisgen
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const onExiting = () => {
        setAnimating(true);
    };
    const onExited = () => {
        setAnimating(false);
    };
    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === testis.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? testis.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    const goToIndex = (newIndex: number) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };
    return (
        <section style={{ backgroundImage: `url(${testiBg})`, padding: '50px 30px', backgroundPosition: 'top center', backgroundSize: 'cover' }}>
            <Container>
                <Row style={{ textAlign: 'center' }}>
                    <Col >
                        <h3  style={{ fontSize: '2.5em', color: '#fff'}} className="header-font">Testimonials</h3>
                        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                            <CarouselIndicators
                                items={testis}
                                activeIndex={activeIndex}
                                onClickHandler={goToIndex}
                            />
                            {testis.map(testi => {
                                return (
                                    <CarouselItem
                                        onExiting={onExiting}
                                        onExited={onExited}
                                        key={testi.id}
                                    >
                                        <Row>
                                            <Col md={5} style={{ textAlign: 'left', color: '#fff' }}>
                                                <div style={{ marginTop: '20%' }}>
                                                    <div>
                                                        <img src={testi.pic} alt="" style={{ borderRadius: '50%', float: 'left', marginRight: 20, width: 64 }} />
                                                        <h4 className="header-font" style={{ marginBottom: 0, fontSize: '1.6em', color: '#fff' }}>{testi.name}</h4>
                                                        <h5> </h5>
                                                    </div>

                                                    <p><i className="fas fa-quote-left pr-2"></i> {testi.text} <i className="fas fa-quote-right pr-2" /></p>
                                                </div>

                                            </Col>
                                            <Col md={7}>
                                                <img src={testi.image} alt={testi.name} />
                                            </Col>
                                        </Row>
                                    </CarouselItem>
                                );
                            })}
                            <a
                                className="carousel-control-prev"
                                data-slide="prev"
                                href="#pablo"
                                onClick={e => {
                                    e.preventDefault();
                                    previous();
                                }}
                                role="button"
                            >
                                <i className="now-ui-icons arrows-1_minimal-left"></i>
                            </a>
                            <a
                                className="carousel-control-next"
                                data-slide="next"
                                href="#pablo"
                                onClick={e => {
                                    e.preventDefault();
                                    next();
                                }}
                                role="button"
                            >
                                <i className="now-ui-icons arrows-1_minimal-right"></i>
                            </a>
                        </Carousel>
                        <Link to="/contact" className="btn btn-warning btn-rounded" style={{ fontSize: '1.2em', color: '#000', fontWeight: 500, marginTop: 50, display: props.colButton  ? '' : 'none' }} >Let Us Help You Write Your Story</Link>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}